* {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 1.6rem;
  margin: 0;
  margin-bottom: 2rem;
}

nav {
  box-shadow: 0 2px 4px rgba(81, 107, 152, 0.16);
  width: calc(100% - 5rem);
  padding: 1.25rem 2.5rem;
  background: #fff;
}

nav .title {
  font-weight: 700;
  color: rgb(31, 31, 31);
  font-size: 1.4rem;
}

nav .logo {
  height: 4.5rem;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.selections {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sticker-select {
  display: flex;
  flex-direction: row;
}

.sticker {
  border-radius: 4px;
  height: 8rem;
  width: 8rem;
  object-fit: contain;
  background: #a29cef;
  margin: 1rem;
  border: 4px solid white;
  cursor: pointer;
  transform: scale(1);
  transition: 250ms ease-out;
}

.sticker.selected {
  border: 4px solid #081526;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: scale(1.1);
}

.progress-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}

.done {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.output {
  width: 100%;
  max-width: 40rem;
  object-fit: contain;
}

input[type='file'] {
  font-family: 'proxima', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #081526;
  border: 2px solid #081526;
  padding: 8px 12px;
  border-radius: 40px;
  display: inline-block;
  -webkit-appearance: none;
  transition: all ease 0.3s;
  margin: 1rem;
  width: 20rem;
  cursor: pointer;
}

input[type='file']:focus {
  outline: none;
  background: transparent;
}

input[type='file']::-webkit-file-upload-button {
  -webkit-appearance: none;
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
}

.thebutton {
  font-family: 'proxima', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #fff;
  background-color: #f55866;
  padding: 8px 12px;
  border-radius: 40px;
  display: inline-block;
  -webkit-appearance: none;
  transition: all ease 0.3s;
  min-width: 20rem;
  margin: 1rem;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #ff8a94;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  font-family: 'proxima', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #081526;
  border: 2px solid #081526;
  padding: 8px 12px;
  border-radius: 40px;
  display: inline-block;
  -webkit-appearance: none;
  transition: all ease 0.3s;
  padding-left: 3rem;

  width: 14rem;
  text-align: center;
}

.select {
  margin: 1rem;
  position: relative;
}

.select::after {
  content: '\25BC';
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
  color: #081526;
  font-size: 2rem;
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgb(244, 86, 101);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.share-buttons {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
}

.share-buttons button {
  margin-right: 1rem;
}

.modemagic {
  font-size: 1.6rem;
  padding: 1rem;
  background: #f55866;
  border-radius: 4px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  margin-top: 1rem;
}
